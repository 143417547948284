<template>
   <v-col v-if="show"
      align="left" justify="start"
      cols="12"
      style=""
      :xs="modal.mobileCols||12"
      :sm="modal.mobileCols||12"
      :md="modal.laptopCols||6"
      :lg="modal.laptopCols||6"
      :xl="modal.laptopCols||6"
      class="pa-1"
      :style="{
         'max-width': modal.width&&$vuetify.breakpoint.mdAndUp?modal.width:'none',
         }"
      >
      <div
         v-html="modal.html">
      </div>
   </v-col>
</template>

<script>
export default {
   props: [
      'modal', 
      'customStyle',
      ],
   computed: {
      show: function () {
         if (this.modal.electronHide && this.$root.isElectron) return false
         if (this.modal.laptopHide && this.$vuetify.mdAndUp) return false
         if (this.modal.mobileHide && this.$vuetify.smAndDown) return false
         return true
      }
   }
}
</script>

<style>
   
</style>